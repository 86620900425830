.location-main-section {
  padding: 0 50px;
  .location-your-address {
    p {
      margin: 0;
      font-size: medium;
    }
  }
}
.loading-spin-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  transform: translate(-50%, -50%);
}

.location__services_wrap {
  max-width: 530px;

  .monitoring__report_info {
    margin-bottom: 0px;

    h2.modal__popup_title {
      margin-bottom: 15px;
      text-align: center;
    }

    p.modal__popup_desc {
      color: #1e1e1e;
    }
    p.modal_popup_point {
      text-align: left;
      padding: 0px 35px;
      @media screen and (max-width: 375px) {
        padding: 0px 15px;
      }
      ul {
        li {
          list-style: disc;
        }
      }
    }
  }

  .monitoring__report_img {
    margin: 0 auto;
    margin-bottom: 30px;
    width: max-content;
  }
}
