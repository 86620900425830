.ant-modal-mask {
  backdrop-filter: blur(5px);
}

.login-modal-section {
  .reset-modal-title {
    text-align: center;
    font-size: 25px;
    color: white;
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0 auto;
    transform: translateX(-50%);
    padding: 10px 40px;
    background-color: #4096ff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    line-height: 1;
    @media screen and (max-width: 480px) {
      padding: 5px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      min-height: 40px;
      font-size: 18px;
    }
  }
  .wrap-top-modal {
    .body-modal {
      margin-top: 10px !important;
      padding: 25px;
      .modal-login-form {
        .ant-form-item {
          margin-bottom: 0;
          .ant-col {
            padding-bottom: 0;
          }
        }
      }
      .forget-pass-modal {
        color: #858585;
        text-align: center;
        p {
          margin-bottom: 0px;
        }
      }
      @media screen and (max-width: "480px") {
        .submit-btn-modal {
          margin-top: 20px !important;
          button {
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15),
              0 2px 10px rgba(118, 145, 255, 0.4) !important;
          }
        }
      }
      .submit-btn-modal {
        width: 100%;
        margin-top: 30px;
        button {
          width: 100%;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          padding: 0.75rem 1.5rem;
          border: none;
          border-radius: 1.5rem;
          text-transform: capitalize;
          font-size: 0.85rem;
          line-height: 1.3;
          letter-spacing: 1px;
          color: #fff;
          box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15),
            0 2px 30px rgba(118, 145, 255, 0.4);
          display: flex;
          width: 100%;
          background-color: #4096ff !important;
        }
      }
    }
  }
}
