.Locate-Any-Phone {
  background-image: url("../../assets/home/locate-any-phone-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  .wid-home-banner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: 150px 50px 0;
    align-items: center;
    height: 100%;
    min-height: 100vh;

    .df-first-section {
      display: flex;
      max-width: 1200px;
      width: 100%;
      align-items: center;
      margin: 0 auto;
      justify-content: space-between;

      .right-contant {
        width: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .banner-img {
          position: relative;

          img {
            width: 540px;
            height: 100%;
          }

          .location-banner-img-text {
            position: absolute;
            z-index: 1;
            top: 53%;
            left: 40%;
            font-size: 20px;
            font-weight: 700;
            color: #1664fa;
            height: max-content;
            width: max-content;
            margin: 0;
            rotate: 14deg;

            @media only screen and (max-width: 1028px) {
              top: 53%;
              left: 40%;
              font-size: 16px;
            }
          }

          .location-banner-img-desc {
            position: absolute;
            z-index: 1;
            top: 60%;
            left: 18%;
            font-size: 17px;
            font-weight: 500;
            color: black;
            width: 310px;
            height: max-content;
            margin: 0;
            rotate: 14deg;

            @media only screen and (max-width: 1028px) {
              top: 59%;
              left: 17%;
              font-size: 14px;
              width: 205px;
            }
          }

          .location-banner-img-view {
            position: absolute;
            z-index: 1;
            top: 70%;
            right: 24%;
            font-size: 14px;
            font-weight: 500;
            color: #1664fa;
            width: max-content;
            height: max-content;
            margin: 0;
            text-align: right;
            width: 300px;
            rotate: 15deg;

            @media only screen and (max-width: 1028px) {
              top: 67%;
              right: 23%;
              font-size: 12px;
            }
          }
        }
      }

      .left-contant {
        width: 50%;
        margin-top: -100px;

        .right-text {
          margin-bottom: 20px;
          color: #fff;

          p {
            margin: 0;
          }
        }
        .head-title {
          font-family: "GT Walsheim Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 56px;
          margin-top: 40px;
          line-height: 1.2;
        }

        .heading-title {
          font-family: "GT Walsheim Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 56px;
          margin-top: 40px;
          line-height: 1.2;
          @media screen and (max-width: 1500px) {
            font-size: 42px;
          }
          @media screen and (max-width: 480px) {
            width: 300px;
            font-size: 33px;
            line-height: 1.2;
          }
        }

        .head-semi-title {
          font-family: "GT Walsheim Pro";
          font-style: normal;
          font-weight: 300;
          font-size: 21px;
          line-height: 30px;
        }

        .wrap-right-input-error {
          position: relative;

          p {
            margin: -16px -3px 10px 0px;
            color: white;
            position: absolute;
            background: #ff3855;
            width: max-content;
            padding: 6px 15px;
            top: -10px;
            border-radius: 8px;
            left: 50%;
            box-shadow: 0 4px 5px 2px #fd8a97;
            transform: translateX(-50%);
            line-height: 1.3;
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }

        .head-input {
          display: flex;
          position: relative;
          background: white;
          border-radius: 50px;

          .mobile-input {
            display: none;
          }

          .mobile-desktop {
            display: block;
          }

          .desktop-input,
          .mobile-input {
            border-radius: 50px 0 0 50px;
            background-color: white;
            width: 100px;

            .flag-dropdown {
              width: 100%;

              .selected-flag {
                width: 100%;
                padding: 0px 0 0 12px;
                cursor: pointer;
                background: transparent !important;
              }
            }
          }

          .react-tel-input {
            color: black;

            input {
              padding-left: 44px;
              background-color: white;
              border: none;
            }

            .flag-dropdown {
              padding: 0 5px;
              background: transparent;
              border: none;
            }
          }

          .desk {
            display: block;
            background: transparent;
            border: none;
            margin: auto;
            height: 80%;
            border-left: 1px solid gray;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          .mobile {
            display: none;
            background: transparent;
            border: none;
            height: 80%;
            margin: auto;
            border-left: 1px solid gray;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          .desktop-input-nd {
            border-radius: 0 50px 50px 0;
          }
          /* Chrome, Safari, Edge, Opera */
          .desktop-input-nd::-webkit-outer-spin-button,
          .desktop-input-nd::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          .desktop-input-nd[type="number"] {
            -moz-appearance: textfield;
          }

          input {
            height: 50px;
            width: 100%;
            border-radius: 50px 0 0 50px;
          }

          .input-suffix {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 20px;
            border-radius: 50px;
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 0 17px;
            gap: 10px;
            height: 100%;
          }

          svg {
            cursor: pointer;
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Locate-Any-Phone {
    .wid-home-banner {
      padding: 149px 20px 0;
    }

    .wid-home-banner .df-first-section {
      .left-contant {
        padding-bottom: 50px;
        width: 100%;
        margin-top: 0;
      }

      .right-contant {
        img {
          width: 348px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .desk {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .Locate-Any-Phone {
    .wid-home-banner {
      padding: 93px 20px 0;
    }
  }

  .input-suffix {
    .span-text {
      display: none;
    }
  }

  .Locate-Any-Phone .wid-home-banner .df-first-section {
    .left-contant {
      .head-title {
        width: 220px;
        font-size: 38px;
        line-height: 1.2;
      }

      .head-semi-title {
        font-size: 18px;
        line-height: 25px;
      }

      .head-input .react-tel-input input {
        width: 90px;
      }
    }

    .right-contant {
      display: none !important;
    }
  }

  .Locate-Any-Phone
    .wid-home-banner
    .df-first-section
    .left-contant
    .head-input
    .input-suffix {
    -webkit-appearance: none;
    top: 0;
    bottom: 0;
    height: calc(100% - 6px);
    margin: 3px 0 3px;
    right: 3px;
    aspect-ratio: 1;
    background: #1664fa;
    color: #fff;
    padding: 8px;
    border-radius: 50px;

    svg {
      flex: 0 0 23px;
      height: 23px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .ant-space {
            width: 100% !important;
          }

          .head-input .react-tel-input {
            width: 80px;
            flex: 0 0 80px;

            input {
              padding-left: 40px;
              width: 80px;
            }
          }
        }

        .right-contant {
          img {
            width: 390px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .bg-grad-section {
    .Locate-Any-Phone .wid-home-banner .df-first-section {
      .left-contant {
        width: 100%;

        .heading-title {
          width: 300px;
          font-size: 33px;
          line-height: 1.2;
        }
      }
    }
  }
}

.react-tel-input .country-list .search {
  padding: 10px 10px 6px 10px !important;

  input {
    padding: 5px 10px !important;
  }
}

.no-entries-message {
  font-weight: bold;
}
