.pricing-main-section {
  .container {
    max-width: 1650px;
    width: 100%;
    margin: 0 auto;

    .home-special-offer {
      padding: 50px;

      .specialOffer-container {
        max-width: 1650px;
        width: 100%;
        margin: 0 auto;

        .special-offerbox-partition {
          display: flex;
          gap: 30px;

          .right-contant {
            width: 66%;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;

            .pricing-title {
              .pricing {
                display: none;
              }

              width: 100%;
              display: flex;
              flex-direction: row-reverse;
              align-items: center;

              .ant-select-selector {
                width: 100%;
                height: 42px;
                padding: 5px 15px;
                font-size: 17px;
              }
            }

            .img-box-2 {
              background-color: #ecedef;
              border-radius: 30px;
              height: auto;
              padding: 25px 30px;
              position: relative;

              .box-heading {
                color: white;
                background-color: #333333;
                width: max-content;
                padding: 0 15px;
                border-radius: 20px;
                p {
                  padding: 4px;
                  margin: 0;
                }
              }

              .desc-special {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-top: 25px;
                color: #333333;

                h3 {
                  font-size: 36px;
                  margin: 0;
                  font-weight: 700;
                  display: flex;
                }
                p {
                  max-width: 125px;
                  @media only screen and (max-width: 1024px) {
                    max-width: 100%;
                  }
                }
              }

              .desc-special-span {
                color: #555555;
                margin-top: 30px;
              }

              .bottom-btn {
                position: absolute;
                bottom: 25px;
                left: 25px;
                display: flex;
                width: calc(100% - 50px);
                height: 50px;
                transition: 0.3s;

                a {
                  width: 100%;
                  text-decoration: none;
                }

                .hl_cta_wrap {
                  position: relative;
                  overflow: hidden;
                  width: 100%;
                  height: 100%;
                  padding: 12px 20px;
                  border: 1px solid currentColor;
                  border-radius: 14px;
                  transition: 0.5s ease-in-out;
                  z-index: 1;
                  color: #1664fa;
                  font-weight: 700;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  &:hover {
                    color: #fff !important;
                  }
                }
              }
            }

            .img-box-3 {
              background-color: #1664fa;
              border-radius: 30px;
              height: auto;
              padding: 25px 30px;
              position: relative;

              .box-heading {
                color: #1664fa;
                background-color: #ffffff;
                width: max-content;
                padding: 0 15px;
                border-radius: 20px;

                p {
                  padding: 4px;
                  margin: 0;
                }
              }

              .desc-special {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-top: 25px;
                color: #fff;
                h3 {
                  font-size: 36px;
                  margin: 0;
                  font-weight: 700;
                  display: flex;
                }
                p {
                  max-width: 125px;
                  @media only screen and (max-width: 1024px) {
                    max-width: 100%;
                  }
                }
              }

              .desc-special-span {
                color: #fff;
                margin-top: 30px;
              }

              .bottom-btn {
                position: absolute;
                bottom: 25px;
                left: 25px;
                display: flex;
                width: calc(100% - 50px);
                height: 50px;
                transition: 0.3s;

                a {
                  width: 100%;
                  text-decoration: none;
                }

                .hl_cta_wrap-white {
                  position: relative;
                  overflow: hidden;
                  width: 100%;
                  height: 100%;
                  padding: 12px 20px;
                  border: 1px solid currentColor;
                  border-radius: 14px;
                  border-color: #fff;
                  transition: 0.5s ease-in-out;
                  z-index: 1;
                  background-color: #1664fa;
                  color: #fff;
                  font-weight: 700;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }

            .img-box-2,
            .img-box-3 {
              width: calc(50% - 75px);
            }
          }

          .img-box {
            position: relative;
            background-color: #171c1c;
            height: auto;
            padding: 25px 30px;
            padding-bottom: 0;
            border-radius: 30px;
            width: 33%;
            overflow: hidden;
            color: white;

            p {
              margin: 0;
            }

            .box-heading {
              font-weight: 700;
              font-size: 55px;
              margin-bottom: 20px;
            }

            .desc-special {
              font-weight: 600;
              font-size: 20px;
              margin-bottom: 20px;
            }

            .desc-special-span {
              font-weight: 400;
              font-size: 17px;
              width: 50%;
            }

            .backGroundImg {
              margin-top: -25px;
              width: 100%;
              display: flex;
              justify-content: space-around;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .special-offerbox-partition {
    flex-wrap: wrap;

    .img-box {
      width: 100% !important;

      .box-heading {
        position: relative;
        z-index: 1;
      }

      .desc-special {
        position: relative;
        z-index: 1;
      }

      .desc-special-span {
        width: 100% !important;
        position: relative;
        z-index: 1;
      }

      .backGroundImg {
        margin-top: 30px !important;
        flex-direction: row-reverse;
        justify-content: normal !important;
        position: relative;
        z-index: 0;
      }
    }

    .right-contant {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .pricing-main-section .container .home-special-offer {
    padding: 50px 20px;

    .specialOffer-container .special-offerbox-partition {
      .img-box {
        .box-heading {
          font-size: 35px;
        }

        .desc-special {
          font-size: 18px;
        }

        .backGroundImg {
          margin-top: 20px !important;

          img {
            height: 100%;
            max-width: 100%;
          }
        }
      }

      .right-contant {
        > div {
          flex: 1 1 100%;
          width: auto !important;
        }
      }
    }
  }
}

// New Css

@media only screen and (max-width: 991px) {
  .pricing_page__wrapper {
    .container {
      .home-special-offer {
        .specialOffer-container {
          .special-offerbox-partition {
            .right-contant {
              flex-wrap: unset;
              .img-box-2 {
                width: 50% !important;
              }
              .img-box-3 {
                width: 50% !important;
              }
              .pricing-title {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .pricing_page__wrapper {
    .container {
      .home-special-offer {
        .specialOffer-container {
          .special-offerbox-partition {
            .right-contant {
              flex-wrap: wrap;
              .img-box-2 {
                width: 100% !important;
              }
              .img-box-3 {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
