.cookiesPolicy-main-section {
  .container {
    .wrap {
      padding: 0 20px;

      .dashboard-box {
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
        padding: 2rem;
        border-radius: 1.6rem;
        background-color: #fff;
        box-sizing: border-box;
        margin-top: 30px;
        margin-bottom: 30px;
        @media only screen and (max-width: 767px){
          padding-left: 22px;
          padding-right: 22px;
        }
        .card {
          padding: 0 30px 30px;
          @media only screen and (max-width: 767px){
            padding: 0 0 30px;
          }
          .table {
            overflow: auto;
          }
          .docs-section:first-child {
            border-top: none;
            padding-top: 0;
          }
          .docs-section {
            padding-top: 1.5rem;
            margin-top: 1.5rem;
            border-top: 1px solid #f1f1f5;
            h5 {
              font-weight: 500;
              line-height: 1.2;
              color: #000;
              font-size: 30px;
              margin: 0;
              margin-bottom: 15px;
            }
            p,
            td,
            li,
            a {
              margin: 0;
              color: #868686;
              line-height: 1.4;
              font-size: 15px;
              text-decoration: none;
            }
            .mt-1 {
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
