.unsubscribe-main-section {
  padding: 0;
  .unsubscribe-container {
    max-width: 1650px;
    width: 100%;
    margin: 0 auto;
    .wrap-unsubscribe {
      .df-unsubscribe {
        display: flex;
        gap: 20px;
        justify-content: center;
        padding-top: 50px;
        .unsubscribe-left-contant {
          padding-top: 50px;
          max-width: 500px;
          .df-leftcontant {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .unsubscribe-title {
              h1 {
                font-size: 55px;
                margin: 0;
              }
            }
            .unsubscribe-confirmation {
              color: rgba(0, 0, 0, 0.88);
            }
            .unsubscribe-input-email {
              label {
                color: rgba(0, 0, 0, 0.88);
                line-height: 1.5714285714285714;
                font-size: 14px;
                font-weight: 600;
              }
              .errorInput {
                border-color: red;
              }
              input {
                height: 40px;
              }
            }
            .unsubscribe-email-submit {
              width: 100%;
              .hl_cta_wrap {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;
                padding: 12px 20px;
                border: 1px solid currentColor;
                border-radius: 14px;
                transition: 0.5s ease-in-out;
                z-index: 1;
                background-color: transparent;
                color: #1664fa;
                font-weight: 700;
                font-size: 20px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .df-unsubscribe {
    padding: 0px 45px;
  }
}

@media only screen and (max-width: 590px) {
  .df-unsubscribe {
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 900px) {
  .right-image {
    img {
      width: 300px;
      height: 350px;
    }
  }
  .df-unsubscribe {
    padding-top: 0 !important;
    .unsubscribe-left-contant {
      padding-top: 40px !important;
    }
  }
}
@media only screen and (max-width: 700px) {
  .unsubscribe-right-contant {
    display: none;
  }
  .unsubscribe-left-contant {
    padding-bottom: 50px !important;
  }
}
@media only screen and (max-width: 480px) {
  .unsubscribe-main-section {
    .back-grad-title {
      margin-bottom: 0 !important;
      .pricing-main-title {
        font-size: 30px !important;
      }
    }
    .unsubscribe-container {
      .df-unsubscribe {
        padding-top: 0 !important;

        .unsubscribe-left-contant {
          padding-bottom: 50px !important;
        }
      }
    }
  }
}

.home__unsubscribe_weapper {
  .login-modal-section {
    padding: 10px 10px !important;
  }
}

// New SCSS
@media only screen and (max-width: 575px) {
  .home__unsubscribe_weapper {
    .login-modal-section {
      .login-modal-title {
        max-width: 220px;
        padding: 5px 20px;

        p {
          white-space: normal;
          font-size: 16px;
        }
      }
    }
  }
}

.ant-modal {
  .ant-modal-close:hover {
    background-color: transparent;
  }
}

.theme_dir__rtl.home__unsubscribe_weapper {
  .ant-modal-content {
    button.ant-modal-close {
      right: auto;
      left: 20px;
    }
  }
}

.theme_dir__rtl.home__unsubscribe_weapper {
  button.ant-modal-close {
    span.ant-modal-close-x {
      justify-content: flex-end;
    }
  }
}
