.termsAndConditions-main-section {
  .container {
    width: calc(100% - 40px);
    .dashboard-box {
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      padding: 2rem;
      border-radius: 1.6rem;
      background-color: #fff;
      box-sizing: border-box;
      margin-top: 30px;
      margin-bottom: 30px;
      @media only screen and (max-width: 767px) {
        padding-left: 22px;
        padding-right: 22px;
      }
      .card {
        padding: 0 30px 30px;
        @media only screen and (max-width: 767px) {
          padding: 0 0 30px;
        }
        .docs-section:first-child {
          border-top: none;
          padding-top: 0;
        }
        .docs-section {
          padding-top: 1.5rem;
          margin-top: 1.5rem;
          border-top: 1px solid #f1f1f5;
          .address_line_set {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            img {
              margin: 0px 6px;
              margin-bottom: 1px;
              pointer-events: none;
              @media only screen and (max-width: 580px) {
                margin: 0px 0px;
                margin-bottom: 0px;
              }
            }
          }
          h5 {
            font-weight: 500;
            line-height: 1.4;
            color: #000;
            font-size: 30px;
            margin: 0;
            margin-bottom: 15px;
          }
          p {
            margin: 0;
            color: #868686;
            line-height: 1.4;
            font-size: 15px;
          }
          .mt-1 {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
.ml-1 {
  padding-left: 50px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
#canvasCompany {
  width: 218px;
  height: 12px;
  margin: 0 5px;
  margin-bottom: 3px;
}
#canvasAddress {
  width: 276px;
  height: 12px;
  margin: 0 5px;
}

// New Terms Image

.termsAndConditions-main-section {
  .container {
    .dashboard-box {
      .card {
        .docs-section {
          .address_line_set {
            margin-bottom: 5px;
            .d--block {
              display: block;
              margin: 0px 0px;
            }
            .d--none {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 530px) {
  .termsAndConditions-main-section {
    .container {
      .dashboard-box {
        .card {
          .docs-section {
            .address_line_set {
              .d--block {
                display: none;
              }
              .d--none {
                display: block;
              }
              img {
                margin: 3px 0px;
              }
            }
          }
        }
      }
    }
  }
}
